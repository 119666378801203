<template>
<!-- 赛事-中上 -->
    <div style="position:relative">
		<div class="container darkBack">
			<div class="contentItem lightBack" v-for="(item,index) in list" :key="index">
				<img class="img" :src="item.img" >
				<div>
					<div class="lightGrayFont" style="font-size:0.12rem;">{{item.title}}</div>
					<div class="whiteFont" style="font-size:0.22rem;" >
						<countTo :startVal='item.preNum' :endVal='item.num' :duration='3000'></countTo>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import bus from "../../../assets/js/bus";
import {getAjax, timestamp} from "/src/assets/js/websocket";

	// 引入数字滚动组件
import countTo from 'vue-count-to';
  export default {
    components: { countTo },
		data() {
		    return { 
		        list:[
					{ 
						title:'今年全市赛事总数' , 
						num:32, 
						img:require('../../../assets/images/trophy.png') ,
						preNum:0 
					},
					{ 
						title:'今年全市参赛选手总数' , 
						num:62190, 
						img:require('../../../assets/images/running.png'),
						preNum:0 
					},
				],
				dataTimer:""
		    }
		},
		methods: {
			getData(){
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: "/map/screen/getMatchAll?ranking=总",
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						this.list[0].preNum = this.list[0].num;
						this.list[1].preNum = this.list[1].num;
						this.list[0].num = res.data.match_list.total;
						this.list[1].num = res.data.match_user_total;
					},
				})
			}	
		},
		created () {
			this.list[0].num = 0;
			this.list[1].num = 0;
			bus.$on('all_num',res=>{
			// 	console.log(res)
				this.list[0].num = res.match_all;
				this.list[1].num = res.player_all;
			})
			// console.log(this.list)
		},
		mounted () {
			this.dataTimer = setInterval(() => {
				this.getData()
			}, 300000);
		},
		beforeDestroy () {
			clearInterval(this.dataTimer)
		}
	}
</script>

<style scoped>
	.container{
		width: 5.96rem;
		height: 1.19rem;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.contentItem{
		width: 2.54rem;
		height: 0.81rem;
		display: flex;
		/* justify-content: center; */
        /* justify-content: space-around; */
        padding:0rem 0.1rem;
        box-sizing: border-box;
		align-items: center;
	}
	.img{
		width: 0.33rem;
		height: 0.34rem;
		margin-right: 0.1rem;
        margin:0rem 0.2rem 0rem 0.14rem;
	}
</style>